@import '@aws-amplify/ui-react/styles.css';

html,
body {
  padding: 0;
  margin: 0;
}
:root {
  --amplify-components-fieldcontrol-error-border-color: #ff3030;
  --amplify-components-fieldmessages-error-color:  #ff3030;
  --amplify-components-fieldcontrol-error-focus-box-shadow:  #ff3030;
  --amplify-components-checkbox-button-error-border-color: #ff3030;
  --amplify-components-fieldcontrol-padding-block-start: 5px;
  --amplify-components-fieldcontrol-padding-block-end: 5px;
}

.form-control  {
  box-sizing: border-box;
  color: var(--amplify-components-fieldcontrol-color) !important;
  font-size: var(--amplify-components-fieldcontrol-font-size) !important;
  line-height: var(--amplify-components-fieldcontrol-line-height) !important;
  padding-block-start: var(--amplify-components-fieldcontrol-padding-block-start) !important;
  padding-block-end: var(--amplify-components-fieldcontrol-padding-block-end) !important;
  padding-inline-start: var(--amplify-components-fieldcontrol-padding-inline-start) !important;
  padding-inline-end: var(--amplify-components-fieldcontrol-padding-inline-end) !important;
  transition: all var(--amplify-components-fieldcontrol-transition-duration) !important;
  width: 100% !important;
  border-color: var(--amplify-components-fieldcontrol-border-color) !important;
  border-radius: 10px !important;
  height: 36px !important;
  border-style: var(--amplify-components-fieldcontrol-border-style) !important;
  border-width: var(--amplify-components-fieldcontrol-border-width) !important;
  outline-color: var(--amplify-components-fieldcontrol-outline-color) !important;
  outline-style: var(--amplify-components-fieldcontrol-outline-style) !important;
  outline-width: var(--amplify-components-fieldcontrol-outline-width) !important;
  outline-offset: var(--amplify-components-fieldcontrol-outline-offset) !important;
  gap: var(--amplify-components-field-gap) !important;
  padding-left: 43px !important;
}

.form-control:disabled {
  color: var(--amplify-components-fieldcontrol-disabled-color) !important;
  cursor: var(--amplify-components-fieldcontrol-disabled-cursor) !important;
  border-color: var(--amplify-components-fieldcontrol-disabled-border-color) !important;
  background-color: var(--amplify-components-fieldcontrol-disabled-background-color) !important;
}

.flag-dropdown {
  margin: 2px;
  border-radius: 8px 0 0 8px !important;
}

.selected-flag {
  border-radius: 8px 0 0 8px !important;
}

.amplify-radio__input:checked + .amplify-radio__button {
  color: black !important;
}

.amplify-radio__input:focus + .amplify-radio__button {
  border-color: black !important;
}

.amplify-checkbox__icon {
  background-color: black !important;
}

.form-control:disabled + .flag-dropdown {
  background-color:  var(--amplify-components-fieldcontrol-disabled-background-color) !important;
  border: none;
}

.amplify-alert--error {
  color: #ff3030 !important;
  background-color: #ffe9e9 !important;
}

.amplify-field__error-message {
  line-height: 1;
  @media(min-width: 576px) {
    position: absolute;
    top: 100%;
  }
}

.amplify-field {
  gap: 3px;
  @media(min-width: 576px) {
    margin-bottom: 16px;
    position: relative;
  }
}

.age-input {
  .amplify-field {
    @media(min-width: 576px) {
      margin-bottom: 30px;
    }
  }
}

.phone-with-error input {
  border-color: var(--amplify-components-fieldcontrol-error-border-color) !important;
}

.custom-button {
  width: 100%;
  border-radius: 30px;
  font-weight: 500;
  border-color: white;

  &:hover, &:active, &:focus {
    color: white;
    border-color: white;
  }

  @media(min-width: 576px) {
    &:hover, &:active {
      background: black;
      border-color: white;
      color: white;
    }
  
    &:focus {
      border-color: 'transparent';
      box-shadow: 'none';
    }
  }
}

.custom-button-primary {
  background: white;

  @media(min-width: 576px) {
    &:hover, &:active {
      background: black;
      border-color: black;
      color: white;
    }
  }
}

.custom-button-secondary {
  background: linear-gradient(180deg, #62BF97 0%, #239564 100%);
  color: white;
}

.custom-button-dark {
  background: black;
  color: white;
  @media(min-width: 576px) {
    &:hover, &:active {
      background: white;
      border-color: black;
      color: black;
    }
  }
}

.custom-button-error {
  border: 'none';
  background: 'transparent';
  color: #ff3030;
  @media(min-width: 576px) {
    &:hover, &:active {
      background: #ff3030;
      border-color: #ff3030;
      color: white;
    }
  }
}

.react-tel-input {
  font-family: inherit !important;
}

.amplify-table__td, .amplify-table__th {
  @media(max-width: 576px) {
    font-size: 12px !important;
    padding: 10px;
  }
}